import React from 'react'
import Banner from '../Component/ContactUs/Banner'
import Footer from '../Component/HomePage/Footer'
import MapSection from '../Component/ContactUs/MapSection'

function ContactUs() {
  return (
    <div><Banner/>
    <MapSection/>
    <Footer/>
    </div>
  )
}

export default ContactUs
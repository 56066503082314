import React from 'react'
import img1 from '../../Images/assets-web-1/home/project/project-1.png'
import img2 from '../../Images/assets-web-1/home/project/project-2.png'
import img3 from '../../Images/assets-web-1/home/project/project-3.png'
import img4 from '../../Images/assets-web-1/home/project/project-4.png'
import img5 from '../../Images/assets-web-1/home/project/project-5.png'
function ProjectSection() { 
  return (
    <div className='project_section'>
        <h2>A Glimpse into Our Projects</h2>
        <p className='text-center my-3 px-5'>Journey through captivating spaces cherished by their owners, satisfying aesthetic senses. Each project possesses a unique persona, transcending the notion of mere construction into an enduring art form that can withstand the test of time. </p>
        <div className='container-fluid px-5'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 col-12'>
                    <div style={{backgroundImage:`url(${img1})`}} className='card_main'>
                    <h5>Lavish Haven</h5>
                    <p>Dark Facade</p>
                    <a href='https://www.naskstudio.com/House-253-C-VT/index.htm' target='_blank'  rel="noreferrer">VIEW DETAIL</a>
                    </div>
                </div>
                <div className='col-lg-8 col-md-6 col-12'>
                    <div style={{backgroundImage:`url(${img2})`,backgroundPosition:"top"}} className='card_main'>
                    <h5>Majestic Views</h5>
                    <p>Dark Facade</p>
                                       <a href='https://www.naskstudio.com/House-253-C-VT/index.htm' target='_blank'  rel="noreferrer">VIEW DETAIL</a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-12'>
                    <div style={{backgroundImage:`url(${img3})`}} className='card_main'>
                    <h5>Italian Palace</h5>
                    <p>Dark Facade</p>
                                       <a href='https://www.naskstudio.com/House-253-C-VT/index.htm' target='_blank'  rel="noreferrer">VIEW DETAIL</a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-12'>
                    <div style={{backgroundImage:`url(${img4})`}} className='card_main'>
                    <h5>Secluded Luxury</h5>
                    <p>Dark Facade</p>
                                       <a href='https://www.naskstudio.com/House-253-C-VT/index.htm' target='_blank'  rel="noreferrer">VIEW DETAIL</a>
                    </div>
                </div>
                <div className='col-lg-4 col-md-12 col-12'>
                <div style={{backgroundImage:`url(${img5})`}} className='card_main'>
                <h5>Modern Home</h5>
                    <p>Dark Facade</p>
                                       <a href='https://www.naskstudio.com/House-253-C-VT/index.htm' target='_blank'  rel="noreferrer">VIEW DETAIL</a>
</div>
                </div>
              
            </div>

        </div>
        
    </div>
  )
}

export default ProjectSection
import React from 'react'
import Banner from '../Component/OurProject/Banner'
import ProjectSection from '../Component/HomePage/ProjectSection'
import Footer from '../Component/HomePage/Footer'

function OurProject() {
  return (
    <div><Banner/>
    <ProjectSection/>
    <Footer/>
    </div>
  )
}

export default OurProject
import React from 'react'
import DrawerAppBar from '../NavBarFooter/DrawerAppBar'

function Banner() {
  return (
    <div className='home_banner projetct_banner'>
        <DrawerAppBar/>
        <h1>A Glimpse into Our Projects</h1>
        <h4 className='text-center my-3'>Transforming Dreams into Reality</h4>
    </div>
  )
}

export default Banner
import React, { useState } from 'react'
import img from '../../Images/1.png'
import img1 from '../../Images/image/architecture.jpg'
import img2 from '../../Images//image/furniture.jpg'
import img3 from '../../Images//image/interior design.jpg'
import img4 from '../../Images/image/landscaping-2.jpg'
import img5 from '../../Images/image/landscaping.jpg'
function LeaderShip() {
    const [count, setCount] = useState(0)
    return (
        <div className='leader_ship project_section'>
            <h2>Expertise in Construction Management and Landscape Design</h2>
            <div className='what_we_do'>
                <div id='change' className='container-fluid px-5 row'>
                    <div className='col-lg-6 col-md-12 col-12 my-3'>
                        <p style={{ lineHeight: "35px" }}>Bringing over two decades of experience in construction management and
                            landscape design, our founder's expertise is a cornerstone of Pakistan
                            Property’s operational excellence. His profound knowledge and hands-on
                            experience in managing large-scale construction projects ensure that all
                            developments meet the highest standards of quality, functionality, and
                            aesthetic appeal.<br />
                            In the realm of landscape design, his vision goes beyond mere decoration,
                            aiming to create spaces that are both sustainable and transformative. He
                            advocates for designs that respect and enhance the natural surroundings
                            while providing serene and functional outdoor areas for our clients. His
                            approach involves using innovative techniques and materials that are
                            environmentally responsible and aesthetically pleasing.<br />
                            This combined expertise in construction and landscape design not only
                            drives the operational success of our projects but also ensures that they are
                            executed with precision, creativity, and foresight. It is this blend of global
                            entrepreneurial spirit and specialized technical knowledge that sets Pakistan
                            Property apart in the luxury real estate market</p>
                    </div>
                    <div className='col-lg-6 col-md-12 col-12 my-3 d-flex justify-content-end align-items-center'>
                        <div style={{ width: "70%", height: "551px", borderRadius: "10px", overflow: "hidden" }}>

                            <img style={{ width: "100%", height: "100%" }} src={img} alt='...' />
                        </div>
                    </div>

                    {/* <div className='col-lg-5 col-md-12 col-12 my-3 d-flex justify-content-center align-items-center'>
                        <div style={{ width: "90%",borderRadius:"10px",overflow:"hidden", height: "400px" }}>

                            <img style={{ width: "100%", height: "100%" }} src={img2} alt='...' />
                        </div>
                    </div> */}
                    {/* <div className='col-lg-7 col-md-12 col-12 my-3  p-5'>
                        <h1>Furniture</h1>
                        <p style={{ lineHeight: "30px", padding: "1rem" }}> At Pakistan Property, we offer custom furniture design and 
procurement services to complement our interior design offerings. Our 
bespoke furniture is crafted to match the specific style and needs of each 
space, using only the highest quality materials and craftsmanship. Whether 
you're looking for statement pieces or functional fixtures, our furniture 
solutions are designed to elevate any environment.</p>
                    </div>
            
                    <div className='col-lg-7 col-md-12 col-12 my-3  p-5 d-flex flex-column justify-content-center'>
                        <h1>Architecture</h1>
                        <p style={{ lineHeight: "30px", padding: "1rem" }}>Our architectural services are at the forefront of design and 
functionality. With a team of highly skilled architects, we create innovative 
and sustainable designs that not only reflect the latest trends but also 
respect the local culture and environment. Whether it's a bespoke luxury 
home or a high-rise commercial complex, our projects are designed to 
inspire and impress.</p>
                    </div>
                    <div className='col-lg-5 col-md-12 col-12 my-3 d-flex justify-content-center align-items-center'>
                        <div style={{ width: "90%",borderRadius:"10px",overflow:"hidden", height: "400px" }}>

                            <img style={{ width: "100%", height: "100%" }} src={img1} alt='...' />
                        </div>
                    </div>
                          <div className='col-lg-5 col-md-12 col-12 my-3 d-flex justify-content-center align-items-center'>
                        <div style={{ width: "90%",borderRadius:"10px",overflow:"hidden", height: "400px" }}>

                            <img style={{ width: "100%", height: "100%" }} src={img3} alt='...' />
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-12 col-12 my-3  p-5'>
                        <h1>Interior Design</h1>
                        <p style={{ lineHeight: "30px", padding: "1rem" }}>Our interior design solutions are tailored to enhance 
aesthetics and functionality. We work closely with our clients to transform 
their visions into reality, creating spaces that are both beautiful and 
practical. From selecting luxurious finishes to designing bespoke furniture, 
our interior designers ensure every detail contributes to a cohesive and 
captivating environment.</p>
                    </div>
                    <div className='col-lg-7 col-md-12 col-12 my-3  p-5 d-flex flex-column justify-content-center'>
                        <h1>Landscaping</h1>
                        <p style={{ lineHeight: "30px", padding: "1rem" }}>We believe that the exterior of a property is just as important 
as the interior. Our landscaping services are designed to enhance the natural
beauty of your property while also providing functional outdoor spaces. From
serene garden designs to elaborate outdoor entertainment areas, our team 
ensures every landscape project is a harmonious extension of the property 
itself.</p>
                    </div>
                    <div className='col-lg-5 col-md-12 col-12 my-3 d-flex justify-content-center align-items-center'>
                        <div style={{ width: "90%",borderRadius:"10px",overflow:"hidden", height: "400px" }}>

                            <img style={{ width: "100%", height: "100%" }} src={img5} alt='...' />
                        </div>
                    </div> */}
                </div>
            </div>
            <h2 className='text-center'>Our Expertise</h2>
            <div className='container-fluid px-5 py-5 row'>
                <div className='col-lg-4 col-md-6 col-12'>
                    <ul className='p-0 our_experties_ul'>
                        <li onClick={() => setCount(0)} className={`${count === 0 ? "active_li" : ""}`}>Architecture</li>
                        <li onClick={() => setCount(1)} className={`${count === 1 ? "active_li" : ""}`}>Interior Design</li>
                        <li onClick={() => setCount(2)} className={`${count === 2 ? "active_li" : ""}`}>Construction & Gray Structure</li>
                        <li onClick={() => setCount(3)} className={`${count === 3 ? "active_li" : ""}`}>Furniture</li>
                        <li onClick={() => setCount(4)} className={`${count === 4 ? "active_li" : ""}`}>Landscaping</li>
                    </ul>
                </div>
                <div style={{height:"200px"}}  className='col-lg-8 col-md-6 col-12 p-0 m-0'>
                    {count === 0 && <div className='row'>
                        <div className='col-2'>
                            <img src={img1} style={{ width: "100%" }} alt='...' />
                        </div>
                        <div className='col-8'>
                            <p style={{ lineHeight: "35px", padding: "0 1rem" }}>Our architectural services are at the forefront of design and functionality. With a team of highly skilled architects, we create innovative and sustainable designs that not </p>
                        </div>
                        <div style={{ lineHeight: "35px" }} className='col-12'>only reflect the latest trends but also respect the local culture and environment. Whether it's a bespoke luxury home or a high-rise commercial complex, our projects are designed to inspire and impress.</div>
                    </div>}
                    {count === 1 && <div className='row'>
                        <div className='col-2'>
                            <img src={img2} style={{ width: "100%" }} alt='...' />
                        </div>
                        <div className='col-8'>
                            <p style={{ lineHeight: "35px", padding: "0 1rem" }}>Our interior design solutions are tailored to enhance aesthetics and functionality. We work closely with our clients to transform their visions into reality, creating spaces that are both beautiful and practical. From selecting luxurious</p>
                        </div>
                        <div style={{ lineHeight: "35px", paddingRight: "1rem" }} className='col-12'>finishes to designing bespoke furniture, our interior designers ensure every detail contributes to a cohesive and captivating environment.</div>
                    </div>}
                    {count === 2 && <div className='row'>
                        <div className='col-2'>
                            <img src={img3} style={{ width: "100%" }} alt='...' />
                        </div>
                        <div className='col-8'>
                            <p style={{ lineHeight: "35px", padding: "0 1rem" }}>We manage all aspects of construction,
                                from foundational work to the finishing touches. Our approach to gray
                                structure construction emphasizes durability and precision, ensuring that
                                every building meets rigorous standards of quality and safety. </p>
                        </div>
                        <div style={{ lineHeight: "35px" }} className='col-12'>By integrating
                            advanced technology and sustainable practices, we deliver structures that
                            are built to last.</div>
                    </div>}
                    {count === 3 && <div className='row'>
                        <div className='col-2'>
                            <img src={img4} style={{ width: "100%" }} alt='...' />
                        </div>
                        <div className='col-8'>
                            <p style={{ lineHeight: "35px", padding: "0 1rem" }}>At Pakistan Property, we offer custom furniture design and
                                procurement services to complement our interior design offerings. Our
                                bespoke furniture is crafted to match the specific style and needs of each
                                space, using only the highest quality materials and craftsmanship. </p>
                        </div>
                        <div style={{ lineHeight: "35px" }} className='col-12'>Whether
                            you're looking for statement pieces or functional fixtures, our furniture
                            solutions are designed to elevate any environment.</div>
                    </div>}
                    {count === 4 && <div className='row'>
                        <div className='col-2'>
                            <img src={img5} style={{ width: "100%" }} alt='...' />
                        </div>
                        <div className='col-8'>
                            <p style={{ lineHeight: "35px", padding: "0 1rem" }}>We believe that the exterior of a property is just as important
                                as the interior. Our landscaping services are designed to enhance the natural
                                beauty of your property while also providing functional outdoor spaces. From
                                serene garden designs to elaborate outdoor entertainment areas, our team
                                ensures every landscape project is a harmonious extension of the property
                                itself.</p>
                        </div>
                        <div style={{ lineHeight: "35px" }} className='col-12'>Each of these services is provided with a commitment to excellence and a
                            focus on sustainable, cost-effective solutions. At Pakistan Property, we're not
                            just building properties; we're crafting environments that enhance and
                            inspire everyday living.</div>
                    </div>}
                </div>
            </div>


        </div>
    )
}

export default LeaderShip
import React from 'react'
import { FaBusinessTime, FaWhatsapp } from 'react-icons/fa'
import { FaSquarePhone } from 'react-icons/fa6'
import { IoMail } from 'react-icons/io5'
function MapSection() {
  return (
    <div className='map_section my-5'>
      <div className='container-fluid px-5'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-12'>
            <h1>Contact US</h1>
            <p>Pakistan Property stands as a boutique architecture and design consultancy firm, specializing in a comprehensive range of services, including strategy, architecture, interior design, project management, supervision, and furniture. With a proven track record, we have contributed to the success of numerous residential and commercial projects within both the public and private sectors. In a relatively short span, Pakistan Property has become synonymous with professionalism and expertise, cementing its reputation in the industry.</p>
            <ul>
              <li><FaWhatsapp className='mx-2' />0305-1115551</li>
              <li><FaSquarePhone className='mx-2' /> 0305-1115551</li>
              <li><IoMail className='mx-2' />social@pakistanproperty.com</li>
              <li><FaBusinessTime className='mx-2' />Mon-Sat 9:00am - 10:00pm</li>
            </ul>
          </div>
          <div className='col-lg-6 col-md-6 col-12'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.0708654771606!2d74.43680187614099!3d31.52221354705003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190ff8d2ad232d%3A0x9d102d2e48fd10ba!2sPakistan%20Property!5e0!3m2!1sen!2s!4v1709803441277!5m2!1sen!2s" width="100%" height="450" title='location' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapSection
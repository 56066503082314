import React from 'react'
import DrawerAppBar from '../NavBarFooter/DrawerAppBar'

function Banner() {
  return (
    <div className='home_banner'>
        <DrawerAppBar/>
        <h1>CONTACT US</h1>
        <h4 className='text-center my-3'>RESIDENTIAL | COMMERCIA</h4>
        <h2 style={{fontSize:"3vmax"}} className='text-center my-3'>Our Experts Will bs Happy to help you</h2>
    </div>
  )
}

export default Banner
import React from 'react'
import img1 from '../../Images/assets-web2/Interior Design/gallery/project-1.png'
import img2 from '../../Images/assets-web2/Interior Design/gallery/project-2.png'
import img3 from '../../Images/assets-web2/Interior Design/gallery/project-3.png'
import img4 from '../../Images/assets-web2/Interior Design/gallery/project-4.png'
import img5 from '../../Images/assets-web2/Interior Design/gallery/project-5.png'
import img6 from '../../Images/assets-web2/Interior Design/gallery/project-6.png'
import img7 from '../../Images/assets-web2/Interior Design/gallery/project-7.png'
import img8 from '../../Images/assets-web2/Interior Design/gallery/project-8.png'
import img9 from '../../Images/assets-web2/Interior Design/gallery/project-9.png'
import img10 from '../../Images/assets-web2/Interior Design/gallery/project-10.png'
function AboutSection() {
  return (
    <div style={{overflowX:"hidden"}} className='value'>
    <div className='m-auto' style={{width:"max-content"}}>

            <h2 style={{textAlign:"center",padding:"1rem",borderBottom:"2px solid",width:"max-content"}}>Express Your Style Through Thoughtfully Designed Interiors</h2>
    </div>
    <div className='container'>
        <p className='text-center'>Interior design is a harmonious blend of artistic expression and scientific precision. Our team of professionals crafts spaces that not only mirror your unique style but also evoke a profound love for your living environment. Functionality is a cornerstone of every well-designed interior, ensuring that beauty seamlessly integrates with practicality. We strive to achieve a timeless aesthetic that captivates every observer with its enduring appeal.</p>
    </div>
    <div className='project_section'>
        <div className='container-fluid px-5'>
            <div className='row'>
                <div style={{height:"500px"}}  className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img1} alt='...'/>
                
                </div>
                <div style={{height:"500px"}} className='col-lg-8 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img2} alt='...'/>
                
                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img3} alt='...'/>
            
                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img4} alt='...'/>
            
                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-12 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img5} alt='...'/>

                </div>
              
            </div>
            <div className='row'>
                <div style={{height:"500px"}} className='col-lg-8 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img6} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img7} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img8} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img9} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-12 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img10} alt='...'/>

                </div>
              
            </div>

        </div>
        
    </div>
</div>
  )
}

export default AboutSection
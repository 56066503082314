import React from 'react'
import Banner from '../Component/InteriorDesign/Banner'
import AboutSection from '../Component/InteriorDesign/AboutSection'
import Footer from '../Component/HomePage/Footer'

function InteriorDesigner() {
  return (
    <div>
        <Banner/>
        <AboutSection/>
        <Footer/>
    </div>
  )
}

export default InteriorDesigner
import React from 'react'
import img from '../../Images/assets-web-1/about/mission.png'
import img2 from '../../Images/assets-web-1/about/vision.png'
function WhatweDo() {
  return (
    <div className='project_section'>
        <h2>WHAT WE DO</h2>
        <p className='text-center my-3 px-5 mb-4'>Welcome to Pakistan Property, your premier provider of luxury real estate 
services across Pakistan. Since our founding in 2018, we have established 
ourselves as leaders in the real estate sector, catering primarily to the sales 
market. Known for our professional expertise and exceptional customer 
service, we specialize in both residential and commercial properties, tailored 
to meet the needs of a diverse and sophisticated clientele.<br/><br/>
At Pakistan Property, we are a dynamic, forward-thinking company that 
blends deep local knowledge with international market expertise. Our team 
comprises seasoned real estate consultants, economists, designers, 
engineers, and architects—all dedicated to exceeding our clients' 
expectations with precision and innovation.
<br/><br/>
At Pakistan Property, we provide a comprehensive suite of luxury real estate 
services designed to cater to every aspect of property ownership and 
development. Here’s a closer look at our specialized services
</p>
        <div className='what_we_do'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-12'>
                    <div style={{backgroundImage:`url(${img2})`,backgroundPosition:"center",backgroundSize:"cover"}} className='card_main'>
                        <div>
                    <h1>Vision</h1>
                    <p className='text-center px-5'>To deliver memorable and trustworthy real estate services that our 
customers will cherish for generations. We strive to uphold a legacy of 
integrity and excellence in every transaction.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12'>
                    <div style={{backgroundImage:`url(${img})`,color:"black",backgroundPosition:"center",backgroundSize:"cover"}} className='card_main'>
                        <div>
                    <h1 style={{color:"black"}}>Mission</h1>
                    <p style={{color:"black"}} className='px-5 text-center'>
Our mission is to be the first choice for our clients and partners in their quest
to find, buy, and manage real estate. We are committed to providing expert 
guidance on the management of ancestral assets and recent acquisitions 
with unmatched professionalism. We aim to deliver strategic advice that 
maximizes returns and guides timely sales, helping our clients make 
informed decisions for optimal investment outcomes.
</p>
                        </div>
                    </div>
                </div>
   
            </div>

        </div>
        
    </div>
  )
}

export default WhatweDo
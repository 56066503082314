import React from 'react'
import DrawerAppBar from '../NavBarFooter/DrawerAppBar'
import { useNavigate } from 'react-router-dom'

function Banner() { 
    const navigate=useNavigate()
  return (
    <div className='home_banner'>
        <DrawerAppBar/>
        <h1>Be inspired by our craftsmanship work</h1>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-4 col-12'>
                    
                    <button onClick={()=>navigate('/Architechure-servise')}>ARCHITECTURE</button>
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                    <button onClick={()=>navigate('/Interior-Designer-servise')}>INTERIOR</button>
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                    <button onClick={()=>navigate('/Construction-servise')}>CONSTRUCTION GREY STRUCTURE</button>
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                    <button>PROJECT MANAGEMENT</button>
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                    <button onClick={()=>navigate('/Furniture-servise')}>FURNITURE DESIGN</button>
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                    <button onClick={()=>navigate('/Landscaping-servise')}>Landscaping</button>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Banner
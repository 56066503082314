import React, { useEffect, useState } from 'react'
import logo from '../../Images/pakistan-property-logo.svg'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FaBars } from 'react-icons/fa';
function  DrawerAppBar() { 
  const [addClass,setClass]=useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(()=>{
    window.screenY=0
  },[])
  return (
    <div >

    <div className={`nav_bar ${addClass&&"active"}`}>
      <div className='logo'>
        <Link to={'/'}>

        <img  src={logo} alt='...' />
        </Link>
      </div>
      <ul className='nav_ul'>
        <li><Link className="link" to={'/'}>Home</Link></li>
        <li><Link className="link" to={'/about-us'}>About Us</Link></li>
        <li><Link className="link" to={'/our-project'}>Our Project</Link></li>
        <li>
        <Link
        style={{textDecoration:"none",color:"white"}}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Our Services
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link style={{color:"black"}} className="link" to={'/Architechure-servise'}>Architechure</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Interior-Designer-servise'}>Interior Designe</Link></MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Construction-servise'}>Construction & Gray Structure</Link></MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Furniture-servise'}>Furniture</Link></MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Landscaping-servise'}>Landscaping</Link></MenuItem>
      </Menu>
        </li>
        <li><Link className="link" to={'/contact-us'}>Contact Us</Link></li>
      </ul>
      <button onClick={()=>setClass(!addClass)} className='bar_btn' style={{color:"white",background:"none",border:"none",fontSize:"25px"}}><FaBars /></button>
    </div>
        <div className={`sidebar ${addClass && "active"}`}>
        <ul className='nav_ul'>
        <li><Link className="link" to={'/'}>Home</Link></li>
        <li><Link className="link" to={'/about-us'}>About Us</Link></li>
        <li><Link className="link" to={'/our-project'}>Our Project</Link></li>
        <li>
        <Link
        style={{textDecoration:"none",color:"black"}}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Our Services
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link style={{color:"black"}} className="link" to={'/Architechure-servise'}>Architechure</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Interior-Designer-servise'}>Interior Designe</Link></MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Construction-servise'}>Construction & Gray Structure</Link></MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Furniture-servise'}>Furniture</Link></MenuItem>
        <MenuItem onClick={handleClose}> <Link style={{color:"black"}} className="link" to={'/Landscaping-servise'}>Landscaping</Link></MenuItem>
      </Menu>
        </li>
        <li><Link className="link" to={'/contact-us'}>Contact Us</Link></li>
      </ul>
        </div>
    </div>
  )
}

export default  DrawerAppBar
import React from 'react'
import img1 from '../../Images/assets-web2/Architecture/gallaery/project-1.png'
import img2 from '../../Images/assets-web2/Architecture/gallaery/project-2.png'
import img3 from '../../Images/assets-web2/Architecture/gallaery/project-3.png'
import img4 from '../../Images/assets-web2/Architecture/gallaery/project-4.png'
import img5 from '../../Images/assets-web2/Architecture/gallaery/project-5.png'
import img6 from '../../Images/assets-web2/Architecture/gallaery/project-6.png'
import img7 from '../../Images/assets-web2/Architecture/gallaery/project-7.png'
import img8 from '../../Images/assets-web2/Architecture/gallaery/project-8.png'
import img9 from '../../Images/assets-web2/Architecture/gallaery/project-9.png'
import img10 from '../../Images/assets-web2/Architecture/gallaery/project-10.png'

function AboutSection() {
  return (
    <div style={{overflowX:"hidden"}} className='value'>
    <div className='m-auto' style={{width:"max-content"}}>

            <h2 style={{textAlign:"center",padding:"1rem",borderBottom:"2px solid",width:"max-content"}}>The fusion of Art and Science defines the essence of Architecture</h2>
    </div>
    <div className='container'>
        <p className='text-center'>At Pakistan Property, our commitment is anchored in meticulous and sincere craftsmanship. Our design ethos harmonizes practicality with opulence, ensuring our clients' voices are not only heard but deeply understood throughout the project's conception. At Pakistan Property, our purpose is to craft designs that evoke joy and fulfillment.</p>
    </div>
    <div className='project_section'>
        <div className='container-fluid px-5'>
            <div className='row'>
                <div style={{height:"500px"}}  className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img1} alt='...'/>
                
                </div>
                <div style={{height:"500px"}} className='col-lg-8 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img2} alt='...'/>
                
                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img3} alt='...'/>
            
                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img4} alt='...'/>
            
                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-12 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img5} alt='...'/>

                </div>
              
            </div>
            <div className='row'>
                <div style={{height:"500px"}} className='col-lg-8 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img6} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img7} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img8} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img9} alt='...'/>

                </div>
                <div style={{height:"500px"}} className='col-lg-4 col-md-12 col-12'>
                    <img style={{height:"100%",width:"100%"}} src={img10} alt='...'/>

                </div>
              
            </div>

        </div>
        
    </div>
</div>
  )
}

export default AboutSection
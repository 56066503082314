import React from 'react'
import DrawerAppBar from '../NavBarFooter/DrawerAppBar'

function Banner() {
  return (
    <div className='home_banner'>
        <DrawerAppBar/>
        <h1 style={{textTransform:"uppercase"}}>CONSTRUCTION GREY STRUCTURE</h1>
        <h4 className='text-center my-3'>RESIDENTIAL|COMMERCIAL</h4>
    </div>
  )
}

export default Banner
import React from 'react'

function VideoSection() {
  return (
    <div className='container-fluid px-5'>
  <iframe style={{borderRadius:"20px"}} width="100%" height="600" src="https://www.youtube.com/embed/e29M8JC4dew?si=oHFLqE2gs8nYdpP4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  )
}

export default VideoSection
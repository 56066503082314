import React from 'react'
import img1 from '../../Images/assets-web-1/home/services/Architecture.png'
import img2 from '../../Images/assets-web-1/home/services/Construction- Grey Structure.png'
import img3 from '../../Images/assets-web-1/home/services/Furniture.png'
import img4 from '../../Images/assets-web-1/home/services/Interior Design.png'
import img5 from '../../Images/assets-web-1/home/services/Landscaping.png'
function OurServiceSection() {
    return (
        <div className='project_section'>
            <h2>Our Service</h2>
            <p className='text-center my-3'>Within Pakistan Property, our dedicated teams of Architects, Interior Designers, Furniture Designers, Landscaping Experts, Engineers, and Project Managers unite to bring your dream projects to life </p>
            <div id='main_box' className='container-fluid px-5'>
                <div className='card_main_service'>
                    <img src={img1} style={{ height: "100%", width: "100%", borderRadius: "5px" }} alt='...' />
                    <div style={{ position: "relative", height: "100%" }}>
                        <div className='d-flex justify-content-center align-items-end' style={{ height: "100%", paddingBottom: "5rem" }}>
                            <h4>Architects</h4>
                        </div>
                    </div>
                </div>
                <div className='card_main_service'>

                    <img src={img2} style={{ height: "100%", width: "100%", borderRadius: "5px" }} alt='...' />
                    <div style={{ position: "relative", height: "100%" }}>
                        <div className='d-flex justify-content-center align-items-end' style={{ height: "100%", paddingBottom: "5rem" }}>
                            <h4>Construction</h4>
                        </div>
                    </div>
                </div>
                <div className='card_main_service '>
                    <img src={img3} style={{ height: "100%", width: "100%", borderRadius: "5px" }} alt='...' />
                    <div style={{ position: "relative", height: "100%" }}>
                        <div className='d-flex justify-content-center align-items-end' style={{ height: "100%", paddingBottom: "5rem" }}>
                            <h4>Furniture</h4>
                        </div>
                    </div>

                </div>
                <div className='card_main_service '>
                    <img src={img4} style={{ height: "100%", width: "100%", borderRadius: "5px" }} alt='...' />
                    <div style={{ position: "relative", height: "100%" }}>
                        <div className='d-flex justify-content-center align-items-end' style={{ height: "100%", paddingBottom: "5rem" }}>
                            <h4>Interior</h4>
                        </div>
                    </div>

                </div>
                <div className='card_main_service i_card'>
                    <img src={img5} style={{ height: "100%", width: "100%", borderRadius: "5px" }} alt='...' />
                    <div style={{ position: "relative", height: "100%" }}>
                        <div className='d-flex justify-content-center align-items-end' style={{ height: "100%", paddingBottom: "5rem" }}>
                            <h4>Landscaping</h4>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default OurServiceSection
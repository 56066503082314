import React from 'react'
import Banner from '../Component/AboutUs/Banner'
import WhatweDo from '../Component/AboutUs/WhatweDo'
import LeaderShip from '../Component/AboutUs/LeaderShip'
import Footer from '../Component/HomePage/Footer'

function AboutUs() {
  return (
    <div>
        <Banner/>
        <WhatweDo/>
        <LeaderShip/>
        <br/>
        <Footer/>
    </div>
  )
}

export default AboutUs
import React from 'react'
import Banner from '../Component/Furniture/Banner'
import AboutSection from '../Component/Furniture/AboutSection'
import Footer from '../Component/HomePage/Footer'
function Furniture() {
  return (
    <div>
    <Banner/>
    <AboutSection/>
    <Footer/>
    </div>
  )
}

export default Furniture
import React from 'react'
import DrawerAppBar from '../NavBarFooter/DrawerAppBar'

function Banner() {
  return (
    <div className='home_banner about_banner'>
        <DrawerAppBar/>
        <h1>ABOUT US</h1>
        <h4 className='text-center my-3'>We are a team of professionals and passion</h4>
    </div>
  )
}

export default Banner
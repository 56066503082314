import React from 'react'
import img1 from '../../Images/Gery Structure/1.jpg'
import img2 from '../../Images/Gery Structure/2.jpg'
import img3 from '../../Images/Gery Structure/3.jpg'
import img4 from '../../Images/Gery Structure/4.jpg'
import img5 from '../../Images/Gery Structure/5.jpg'
import img6 from '../../Images/Gery Structure/7.jpg'
import img7 from '../../Images/Gery Structure/6.jpg'

function AboutSection() {
  return (
    <div style={{overflowX:"hidden"}} className='value'>
    <div className='m-auto' style={{width:"max-content"}}>

            <h2 style={{textAlign:"center",padding:"1rem",borderBottom:"2px solid",width:"max-content"}}>Creating Structures to Withstand the Test of Time</h2>
    </div>
    <div className='container'>
        <p className='text-center'>Our team of experts approaches every project with a profound commitment to precision and meticulous attention. We firmly believe that construction is an endeavor meant to endure for generations. Each project demands an unwavering focus on detail and a pursuit of perfection in execution.</p>
    </div>
    <div className='project_section'>
        <div className='container-fluid px-5'>
            <div className='row'>
                <div style={{height:"500px",borderRadius:"10px",overflow:"hidden"}}  className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%",borderRadius:"10px",}} src={img1} alt='...'/>
                
                </div>
                <div style={{height:"500px",borderRadius:"10px",overflow:"hidden"}} className='col-lg-8 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%",borderRadius:"10px",}} src={img2} alt='...'/>
                
                </div>
                <div style={{height:"500px",borderRadius:"10px",overflow:"hidden"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%",borderRadius:"10px",}} src={img3} alt='...'/>
            
                </div>
                <div style={{height:"500px",borderRadius:"10px",overflow:"hidden"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%",borderRadius:"10px",}} src={img4} alt='...'/>      
                </div>
                <div style={{height:"500px",borderRadius:"10px",overflow:"hidden"}} className='col-lg-4 col-md-12 col-12'>
                    <img style={{height:"100%",width:"100%",borderRadius:"10px",}} src={img5} alt='...'/>
                </div>
              
            </div>
            <div className='row'>
                <div style={{height:"500px",borderRadius:"10px",overflow:"hidden"}} className='col-lg-8 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%",borderRadius:"10px"}} src={img6} alt='...'/>

                </div>
                <div style={{height:"500px",borderRadius:"10px",overflow:"hidden"}} className='col-lg-4 col-md-6 col-12'>
                    <img style={{height:"100%",width:"100%",borderRadius:"10px",}} src={img7} alt='...'/>

                </div>
                
              
            </div>

        </div>
        
    </div>
</div>
  )
}

export default AboutSection
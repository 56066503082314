import React from 'react'
import img1 from '../../Images/land-scaping/land-scaping/gallery/project-1.png'
import img2 from '../../Images/land-scaping/land-scaping/gallery/project-2.png'
import img3 from '../../Images/land-scaping/land-scaping/gallery/project-3.png'
import img4 from '../../Images/land-scaping/land-scaping/gallery/project-4.png'
import img5 from '../../Images/land-scaping/land-scaping/gallery/project-5.png'


function AboutSection() {
    return (
        <div style={{ overflowX: "hidden" }} className='value'>
            <div className='m-auto' style={{ width: "max-content" }}>

                <h2 style={{ textAlign: "center", padding: "1rem", borderBottom: "2px solid", width: "max-content" }}>Nature's Retreat: Verdant Havens for Your Escape</h2>
            </div>
            <div className='container'>
                <p className='text-center'>Our fascination with vibrant, green expanses knows no bounds. Landscaping involves harmonizing the multitude of colors, textures, sounds, lines, and curves that nature generously provides</p>
            </div>
            <div className='project_section'>
                <div className='container-fluid px-5'>
                    <div className='row'>
                        <div style={{ height: "500px" }} className='col-lg-4 col-md-6 col-12'>
                            <img style={{ height: "100%", width: "100%" }} src={img1} alt='...' />

                        </div>
                        <div style={{ height: "500px" }} className='col-lg-8 col-md-6 col-12'>
                            <img style={{ height: "100%", width: "100%" }} src={img2} alt='...' />

                        </div>
                        <div style={{ height: "500px" }} className='col-lg-4 col-md-6 col-12'>
                            <img style={{ height: "100%", width: "100%" }} src={img3} alt='...' />

                        </div>
                        <div style={{ height: "500px" }} className='col-lg-4 col-md-6 col-12'>
                            <img style={{ height: "100%", width: "100%" }} src={img4} alt='...' />

                        </div>
                        <div style={{ height: "500px" }} className='col-lg-4 col-md-12 col-12'>
                            <img style={{ height: "100%", width: "100%" }} src={img5} alt='...' />

                        </div>

                    </div>


                </div>

            </div>
        </div>
    )
}

export default AboutSection
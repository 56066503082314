import React from 'react'
import Banner from './Banner'
import AboutSection from './AboutSection'
import Footer from '../HomePage/Footer'

function Architechure() {
  return (
    <div>
        <Banner/>
        <AboutSection/>
        <Footer/>
    </div>
  )
}

export default Architechure
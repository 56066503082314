import React from 'react'
import Banner from '../Component/Construction/Banner'
import AboutSection from '../Component/Construction/AboutSection'
import Footer from '../Component/HomePage/Footer'
function Construction() {
  return (
    <div>
      <Banner/>
      <AboutSection/>
      <Footer/>
      </div>
  )
}

export default Construction
import React from 'react'
import { FaWhatsapp, FaAngleRight, FaLinkedinIn } from "react-icons/fa";
import { FaSquarePhone, FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { FaBusinessTime } from "react-icons/fa";
import logo from '../../Images/pakistan-property-logo.svg'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer p-5'>
            <div className='row'>
                <div style={{ padding: "1rem" }} className='col-lg-3 col-md-6 col-12'>
                    <div className='footer_logo'>
                        <img style={{ width: "150px" }} src={logo} alt='...' />
                        <p>Discover our extensive portfolio by exploring the diverse services provided by Pakistan Property</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-12'>
                    <h5>Contact Us</h5>
                    <ul>
                        <li> <FaWhatsapp className='mx-2' />0305-1115551</li>
                        <li><FaSquarePhone className='mx-2' />0305-1115551</li>
                        <li> <IoMail className='mx-2' />social@pakistanproperty.com</li>
                        <li> <FaBusinessTime className='mx-2' />Mon-Sat 9:00am - 10:00pm</li>
                    </ul>
                </div>
                <div className='col-lg-3 col-md-6 col-12'>
                    <h5>Our Services</h5>
                    <ul>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/Architechure-servise'}>Architecture</Link></li>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/Interior-Designer-servise'}>Interior Design</Link></li>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/Construction-servise'}>Construction and Gray Structure</Link></li>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/Furniture-servise'}>Furniture</Link></li>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/Landscaping-servise'}>Landscaping</Link></li>
                    </ul>
                </div>
                <div className='col-lg-3 col-md-6 col-12'>
                    <h5>Qucik Links</h5>
                    <ul>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/about-us'}>About us</Link></li>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/contact-us'}>Contact Us</Link></li>
                        <li><FaAngleRight className='mx-2' /><Link className="link" to={'/our-project'}>Our Projects</Link></li>
                    </ul>
                </div>
            </div>
            <div className='social_mediaSection'>
                <h1>Follow Us on Social Media</h1>
                <div className='my-3'>
                    <a href='https://www.facebook.com/profile.php?id=100083395718437' target='_blank'  rel="noreferrer">

                        <span><FaFacebookF /></span>
                    </a>
                    <a href='https://www.youtube.com/@PakistanPropertyOfficial' target='_blank'  rel="noreferrer">

                        <span><FaYoutube /></span>
                    </a>
                    <a href='https://twitter.com/Pak_Pro_' target='_blank'  rel="noreferrer">

                        <span><FaTwitter /></span>
                    </a>
                    <a href='https://www.instagram.com/_pakistanproperty_/' target='_blank'  rel="noreferrer">

                        <span><FaInstagram /></span>
                    </a>
                    <a href='https://www.linkedin.com/company/pakistanproperty/' target='_blank'  rel="noreferrer">

                        <span><FaLinkedinIn /></span>
                    </a>
                    <a href='www.tiktok.com/@pakistanproperty.com' target='_blank'  rel="noreferrer">

                        <span><FaTiktok /></span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer
import React from 'react'
import Banner from '../Component/HomePage/Banner'
import ProjectSection from '../Component/HomePage/ProjectSection'
import VideoSection from '../Component/HomePage/VideoSection'
import OurServiceSection from '../Component/HomePage/OurServiceSection'
import Footer from '../Component/HomePage/Footer'

function Home() {
    return (
        <div>
            <Banner />
            <ProjectSection />
            <VideoSection />
            <OurServiceSection />
            <Footer />
        </div>
    )
}

export default Home